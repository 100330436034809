import React from "react";
import "../../components/css/main.css";
import logoJs from "../../assets/images/ambientes/programacao/logo_js.svg";
import logoNodeJs from "../../assets/images/ambientes/programacao/logo_nodejs.svg";
import logoPython from "../../assets/images/ambientes/programacao/logo_python.svg";
import logoAngular from "../../assets/images/ambientes/programacao/logo_angular.svg";
import logoCss from "../../assets/images/ambientes/programacao/logo_css3.svg";
import logoKafka from "../../assets/images/ambientes/programacao/logo_kafka.svg";
import logoplus from "../../assets/images/ambientes/programacao/logo_c_plus.svg";

import logoMongo from "../../assets/images/ambientes/banco/logo_mongoDB.svg";
import logoPostgres from "../../assets/images/ambientes/banco/logo_postgresql.svg";
import logoSqlServer from "../../assets/images/ambientes/banco/logo_sqlserver.svg";
import logoOracle from "../../assets/images/ambientes/banco/logo_oracle.svg";

import logo_hadoop from "../../assets/images/ambientes/arquitetura/logo_apache_hadoop.svg";
import logo_apache from "../../assets/images/ambientes/arquitetura/logo_apache_hbase.svg";
import logo_apache_spark from "../../assets/images/ambientes/arquitetura/logo_apache_spark.svg";
import logo_aws from "../../assets/images/ambientes/arquitetura/logo_aws.svg";
import logo_azure from "../../assets/images/ambientes/arquitetura/logo_azure.svg";
import logo_google_cloud from "../../assets/images/ambientes/arquitetura/logo_google_cloud.svg";
import logo_redis from "../../assets/images/ambientes/arquitetura/logo_redis.svg";

import logo_docker from "../../assets/images/ambientes/container/logo_docker.svg"
import logo_kubernetes from "../../assets/images/ambientes/container/logo_kubernetes.svg"

import logo_jenkins from "../../assets/images/ambientes/automacao/logo_jenkins.svg";

import logo_android from "../../assets/images/ambientes/frameworks/logo_android.svg";
import logoFlutter from "../../assets/images/ambientes/frameworks/logo_flutter.svg";
import logo_react from "../../assets/images/ambientes/programacao/logo_react.svg";
import logo_react_native from "../../assets/images/ambientes/programacao/logo_react_native.svg";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";


export default function SectionAmbiente() {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: "(max-width: 1224px)" })

    return (
        <section class="section-ambientes pt-0">

            <div class="container" style={{backgroundColor: "white"}}>
                <div class="row">
                    <div class="col">
                        <h2 class="wow fadeIn" data-wow-delay="0.4s" >{t("home.ambiente.title")}</h2>
                    </div>
                </div>
            </div>


            <div class="container-fluid bg-ambientes">
                {isMobile ? <div id="carousel-client" class="carousel" data-ride="carousel" >
                    <ol class="carousel-indicators" style={{ bottom: -60 , backgroundColor: "#004e77"}}>
                        <li data-target="#carousel-client" data-slide-to="0" class="active" style={{ backgroundColor: "rgb(255,255,255" }}></li>
                        <li data-target="#carousel-client" data-slide-to="1" style={{ backgroundColor: "rgb(255,255,255" }}></li>
                        <li data-target="#carousel-client" data-slide-to="2" style={{ backgroundColor: "rgb(255,255,255" }}></li>
                        <li data-target="#carousel-client" data-slide-to="3" style={{ backgroundColor: "rgb(255,255,255" }}></li>
                        <li data-target="#carousel-client" data-slide-to="4" style={{ backgroundColor: "rgb(255,255,255" }}></li>
                        <li data-target="#carousel-client" data-slide-to="5" style={{ backgroundColor: "rgb(255,255,255" }}></li>
                        <li data-target="#carousel-client" data-slide-to="6" style={{ backgroundColor: "rgb(255,255,255" }}></li>
                    </ol>
                    <div class="carousel-inner">

                        <div class="carousel-item active" style={{height: 300, paddingTop:"40px"}}>
                            <div class="row mt-2" id="item" style={{ paddingLeft: "20px", paddingRight: "20px" }}>

                                <div class="col">
                                    <div className="row mt-2" id="item" style={{ display: "flex",  alignItems: "center"}}>
                                        <div className="col sm-2" style={{ width: 50 }}>
                                            <img src={logoJs} alt="Algar" class="mx-auto" style={{ width: 180, height: 65 }} />
                                        </div>
                                        <div className="col sm-2" style={{ width: 50 }}>
                                            <img src={logoplus} alt="Algar" class="mx-auto" style={{ width: 180, height: 65 }} />
                                        </div>
                                    </div>
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <div className="row mt-2" id="item" style={{ display: "flex",  alignItems: "center"}}>
                                        <div className="col sm-2" style={{ width: 50 }}>
                                            <img src={logoCss} alt="Algar" class="mx-auto" style={{ width: 180, height: 65 }} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2" id="item" style={{ display: "flex",  alignItems: "center"}}>
                                    <div className="col sm-2" style={{ width: 50 }}>
                                        <img src={logoPython} alt="Algar" class="mx-auto" style={{ width: 180, height: 65 }} />
                                    </div>
                                    <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                        <img src={logoKafka} alt="Algar" class="mx-auto" style={{ width: 180, height: 65 }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div class="carousel-item" style={{height: 300, paddingTop:"20px"}}>
                            <div class="row mt-3" id="item" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_docker} alt="Chesf" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_kubernetes} alt="Ciena" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_jenkins} alt="Huawei" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" style={{height: 300, paddingTop:"0"}}>

                            <div class="row mt-3" id="item" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logoMongo} alt="Costanta" class="mx-auto" style={{ width: 180, height: 60 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logoPostgres} alt="DPR" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logoSqlServer} alt="" class="mx-auto" style={{ width: 180, height: 75 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logoOracle} alt="" class="mx-auto" style={{ width: 180, height: 50 }} />
                                </div>
                                
                            </div>
                        </div> 

                        <div class="carousel-item" style={{height: 300, paddingTop:"0"}}>
                            <div class="row mt-3" id="item" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logoNodeJs} alt="Elektro" class="mx-auto" style={{ width: 170, height: 50 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logoAngular} alt="Iberdola" class="mx-auto" style={{ width: 170, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_android} alt="OI" class="mx-auto" style={{ width: 170, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logoFlutter} alt="" class="mx-auto" style={{ width: 170, height: 55 }} />
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" style={{height: 300, paddingTop:"10px"}}>
                            <div class="row mt-3" id="item" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_react} alt="Philips" class="mx-auto" style={{ width: 180, height:80 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_react_native} alt="Positivo" class="mx-auto" style={{ width: 180, height: 80 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_redis} alt="SAP" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                            </div>
                        </div>

                        <div class="carousel-item" style={{height: 300, paddingTop:"20px"}}>
                            <div class="row mt-3" id="item" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_apache_spark} alt="Energisa" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_apache} alt="Equatorial" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_hadoop} alt="Equatorial" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                                
                            </div>
                        </div>

                        <div class="carousel-item" style={{height: 300, paddingTop:"30px"}}>
                            <div class="row mt-3" id="item" style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_aws} alt="OI" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_azure} alt="Philips" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                                    <img src={logo_google_cloud} alt="Positivo" class="mx-auto" style={{ width: 180, height: 65 }} />
                                </div>
                            </div>
                        </div>

                    </div>
                    <button class="carousel-control-prev" type="button" data-target="#carousel-client" data-slide="prev" style={{ paddingRight: "25px" }}>
                        <span class="carousel-control-prev-icon" id="ambiente-prev" aria-hidden="true" style={{ fill: "rgb(255,255,255" }}></span>
                        <span class="sr-only">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-target="#carousel-client" data-slide="next" style={{ paddingLeft: "25px" }}>
                        <span class="carousel-control-next-icon" id="ambiente-next" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </button>
                </div> 
                : <div class="container-fluid bg-ambientes">
                    <div class="row" style={{justifyContent:"center"}}>
                        <div class="col-sm-6 col-md-4 ctn-ambiente animation" style={{width:"220px"}}>
                            <div class="row" style={{paddingTop:"40px"}}>
                                <img src={logoplus} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                <img src={logoJs} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                <img src={logoPython} alt="" class="img-fluid" style={{maxHeight: "55px"}}/>
                                <img src={logoKafka} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                <img src={logoCss} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 ctn-ambiente animation" style={{width:"300px"}}>
                            <div class="col-sm" >
                                <center>
                                    <img src={logo_apache_spark} alt="" class="img-fluid"/>
                                    <img src={logo_apache} alt="" class="img-fluid"/>
                                    <img src={logo_hadoop} alt="" class="img-fluid" />
                                </center>
                            </div>
                            <div class="col-sm">
                                <center>
                                    <img src={logo_aws} alt="" class="img-fluid"/>
                                    <img src={logo_azure} alt="" class="img-fluid"/>
                                    <img src={logo_google_cloud} alt="" class="img-fluid"/>
                                    <img src={logo_redis} alt="" class="img-fluid"/>
                                </center>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 ctn-ambiente animation" style={{width:"240px"}}>
                            <div class="col">
                                <center>
                                    <img src={logo_android} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                    <br /><br />
                                    <img src={logo_react} alt="" class="img-fluid" style={{maxHeight: "60px"}}/>
                                    <br /><br />
                                    <img src={logo_react_native} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                    <br /><br />
                                    <img src={logoNodeJs} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                    <br /><br />
                                    <img src={logoAngular} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                    <img src={logoFlutter} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                </center>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 ctn-ambiente animation" style={{width:"240px"}}>
                            <div class="col" style={{"alignItems":"center", paddingTop:"50px"}}>
                                <center>
                                    <img src={logoMongo} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                    <br/><br/>
                                    <img src={logoPostgres} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                    <br/><br/>
                                    <img src={logoSqlServer} alt="" class="img-fluid" style={{maxHeight: "60px"}}/>
                                    <br/><br/>
                                    <img src={logoOracle} alt="" class="img-fluid" style={{maxHeight: "40px", color:"#fff"}}/>
                                </center>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 ctn-ambiente animation" style={{width:"240px"}}>
                            <div class="col" style={{"alignItems":"center", paddingTop:"75px"}}>
                                <img src={logo_docker} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                <br/><br/><br/>
                                <img src={logo_kubernetes} alt="" class="img-fluid" style={{maxHeight: "40px"}}/>
                                <br/><br/><br/>
                                <img src={logo_jenkins} alt="" class="img-fluid" style={{maxHeight: "50px"}}/>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        </section>
    );
}