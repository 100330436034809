import React, { useEffect } from "react";
import "../css/main.css";
import "../css/animate.css";
import videoMp4 from "../../assets/video/video.mp4";
import videoWebm from "../../assets/video/video.webm";
import $ from 'jquery'
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

export default function IntroHome() {

    const isMobile = useMediaQuery({ query: "(max-width: 1224px)" })
    const {t} = useTranslation();

    useEffect(() => {
        // jQuery is required to run this code
        $(document).ready(function () {
            document.getElementById('video').play();
            scaleVideoContainer();

            initBannerVideoSize('.video-container .poster img');
            initBannerVideoSize('.video-container .filter');
            initBannerVideoSize('.video-container video');

            $(window).on('resize', function () {
                scaleVideoContainer();
                scaleBannerVideoSize('.video-container .poster img');
                scaleBannerVideoSize('.video-container .filter');
                scaleBannerVideoSize('.video-container video');
            });
        })

        function scaleVideoContainer() {
            var height = $(window).height() + 5;
            var unitHeight = parseInt(height) + 'px';
            $('.homepage-hero-module').css('height', unitHeight);
        }

        function initBannerVideoSize(element) {
            $(element).each(function () {
                $(this).data('height', $(this).height());
                $(this).data('width', $(this).width());
            });

            scaleBannerVideoSize(element);
        }

        function scaleBannerVideoSize(element) {

            var windowWidth = $(window).width(),
                windowHeight = $(window).height() + 5,
                videoWidth,
                videoHeight;

            $(element).each(function () {
                var videoAspectRatio = $(this).data('height') / $(this).data('width');

                $(this).width(windowWidth);

                if (windowWidth < 1000) {
                    videoHeight = windowHeight;
                    videoWidth = videoHeight / videoAspectRatio;
                    $(this).css({ 'margin-top': 0, 'margin-left': -(videoWidth - windowWidth) / 2 + 'px' });

                    $(this).width(videoWidth).height(videoHeight);
                }

                $('.homepage-hero-module .video-container video').addClass('fadeIn animated');

            });
        }
    });

    return (
    <section id="inicio" style={{ paddingTop: 0, paddingBottom:0 }}>
            <div className="homepage-hero-module" style={{zIndex:-1}}>
                <div className="video-container">
                    <div className="filter">
                        <div className="content-home">
                            <div className="container h-100">
                                <div className="row h-100 justify-content-center">
                                    <div className="col-sm-6 my-auto" style={{"paddingTop":"120px"}}>
                                        <h1 className="wow fadeInDown" data-wow-delay="0.4s" style={{fontSize:!isMobile ? "" : "48px"}}>
                                            {t("home.intro-home.1")}<span><br/>{t("home.intro-home.2")}</span></h1>
                                        <p className="wow fadeInDown" data-wow-delay="0.6s" style={{fontSize:!isMobile ? "" : "24px"}}>
                                            {t("home.intro-home.3")}<br />{t("home.intro-home.4")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <video autoPlay="autoplay" loop className="fillWidth" muted id="video" style={{ width: '100%' }}>
                        <source src={videoMp4} type="video/mp4" />
                        <source src={videoWebm} type="video/webm" />
                    </video>
                </div>
            </div>
        </section>
    );
}


