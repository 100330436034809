import React from "react";
import "../css/main.css";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import SectionBoxResponsive from "./box-logo/box_responsive";
import SectionBox from "./box-logo/box";
import { useTranslation } from "react-i18next";

export default function SectionClient() {

    const {t} = useTranslation();

    const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    return (
        <section class="section-clientes">
            <div class="container-fluid">

                <div class="row mb-2">
                    <div class="col" style={{display: "flex",
                        justifyContent: "center",
                        textAlign: "center !important"}}>
                        {!isMobile && <h2 class="wow fadeIn" data-wow-delay="0.4s" style={{textAlign: "center"}}>{t("home.cliente")}</h2>}
                        {isMobile && <h2 class="wow fadeIn" style={{fontSize: "2em", textAlign: "center"}} data-wow-delay="0.4s" >{t("home.cliente")}</h2>}
                        
                    </div>
                </div>
                <div class="container-fluid client pt-12 pb-12" >

                     {isMobile &&  <SectionBoxResponsive/>} 
                     {!isMobile && <SectionBox/>}

                </div>
            </div>
        </section>
    );
}