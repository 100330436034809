import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

export default function SectionSolucoes() {

    const { t } = useTranslation();

    return (<section class="section-solucao">

        <div class="container-fluid">

            <div class="row">
                <div class="col-12">

                    <h2 class="wow fadeIn" data-wow-delay="0.4s">{t("menu.solucao")}</h2>

                </div>
            </div>

            <div class="row px-3">
                <div class="col-sm-3 px-sm-0 padding-fitec mb-3">
                    <HashLink to="/solucoes#inicio-solucao">
                        <div class="ctn-solucao animation bg-solucao-4 wow fadeInDown" data-wow-delay="0.8s">
                            <div class="row h-100 align-items-end">
                                <div class="col">
                                    <div class="glass-solucao py-5 d-flex">

                                        <h6 class="text-center">{t("solucao.digital")}</h6>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </HashLink>
                </div>

                <div class="col-sm-3 px-sm-0 padding-fitec mb-3">
                    <HashLink to="/solucoes#IOT">
                        <div class="ctn-solucao animation bg-solucao-3 wow fadeInDown" data-wow-delay="0.6s">
                            <div class="row h-100 align-items-end">
                                <div class="col">
                                    <div class="glass-solucao py-5 d-flex">

                                        <h6 class="text-center">{t("solucao.iot")}</h6>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </HashLink>
                </div>

                <div class="col-sm-3 px-sm-0 padding-fitec mb-3">
                    <HashLink to="/solucoes#MicroredesDeEnergia">
                        <div class="ctn-solucao animation bg-solucao-2 wow fadeInDown" data-wow-delay="0.4s">
                            <div class="row h-100 align-items-end">
                                <div class="col">
                                    <div class="glass-solucao py-5 d-flex">

                                        <h6 class="text-center">{t("solucao.energia")}</h6>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </HashLink>
                </div>

                <div class="col-sm-3 px-sm-0 padding-fitec mb-3 ">
                    <HashLink to="/solucoes#EngenhariaDeRedesIP">
                        <div class="ctn-solucao animation bg-solucao-1 wow fadeInDown" data-wow-delay="0.2s">
                            <div class="row h-100 align-items-end">
                                <div class="col">
                                    <div class="glass-solucao py-5 d-flex">

                                        <h6 class="text-center">{t("solucao.redeIP")}</h6>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </HashLink>
                </div>
            </div>

        </div>

    </section>
    );
}