import './style.css'
import ImageAndTitle from "../../../components/ImageAndTitle"
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next';

function Engineering() {
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: "(max-width: 1224px)" })

    return (
        <>
            <section class="section-intro-page bg-engenharia" id="inicio-engenharia">
                <div class="container h-100">
                    <div class="row h-100 align-items-center">
                        <div class="col-12 text-center">
                            <h1>{t("atuacao.engenharia")}</h1>
                            <ul class="submenu-interno mt-4">
                                <li><a class="page-scroll" href="#redescelulares">{t("atuacao.links-2.1")}</a></li>
                                <li><a class="page-scroll" href="#redesip">{t("atuacao.links-2.2")}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="section-sub-page">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <p style={{ "paddingRight": "20px", "paddingLeft": "10px" }}>{t("atuacao.text-7.1")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <ImageAndTitle imageByClassName={"section-intro-page bg-redes-celulares"} />
            <section class="section-sub-page bg-cinza-1" id="redescelulares">
                <div class="container">
                    <div class="row mb-4">
                        <div class="col-12">
                            <h2>{t("atuacao.links-2.1")}</h2>
                            <p style={{ "paddingRight": "20px", "paddingLeft": "10px" }}>
                                {t("atuacao.text-8.1")}
                                <br /><br />
                                {t("atuacao.text-8.2")}
                                <br /><br />
                                {t("atuacao.text-8.3")}</p>
                        </div>
                    </div>

                    {!isMobile
                        ? <div class="row row-eq-height">
                            <div class="col-4 mb-4">
                                <div class="ctn-eng">
                                    <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.5")}</h3>
                                    <p >{t("atuacao.text-8.6")}</p>
                                </div>
                            </div>
                            <div class="col-4 mb-4">
                                <div class="ctn-eng">
                                    <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.7")}</h3>
                                    <p>{t("atuacao.text-8.8")}</p>
                                </div>
                            </div>
                            <div class="col-4 mb-4">
                                <div class="ctn-eng">
                                    <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.9")}</h3>
                                    <p>{t("atuacao.text-8.10")}</p>
                                </div>
                            </div>
                        </div>
                        : <div>
                            <div class="row row-eq-height" style={{ "justifyContent": "center", "width": "75px"}}>
                                <div class="col-4 mb-4">
                                    <div class="ctn-eng" style={{ "width": "310px" }}>
                                        <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.5")}</h3>
                                        <p >{t("atuacao.text-8.6")}</p>
                                    </div>
                                </div>
                                <div class="row row-eq-height" style={{ "justifyContent": "center", "width": "75px" }}>
                                    <div class="col-4 mb-4">
                                        <div class="ctn-eng" style={{ "width": "310px" }}>
                                            <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.7")}</h3>
                                            <p>{t("atuacao.text-8.8")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row row-eq-height" style={{ "justifyContent": "center", "width": "90px" }}>
                                    <div class="col-4 mb-4">
                                        <div class="ctn-eng" style={{ "width": "310px" }}>
                                            <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.9")}</h3>
                                            <p>{t("atuacao.text-8.10")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    {!isMobile
                        ? <div class="row row-eq-height">
                            <div class="col-4 mb-4">
                                <div class="ctn-eng">
                                    <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.11")}</h3>
                                    <p>{t("atuacao.text-8.12")}</p>
                                </div>
                            </div>
                            <div class="col-4 mb-4">
                                <div class="ctn-eng">
                                    <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.13")}</h3>
                                    <p>{t("atuacao.text-8.14")}</p></div>
                            </div>
                        </div>
                        : <div>
                            <div class="row row-eq-height" style={{ "justifyContent": "center", "width": "90px" }}>
                                <div class="col-4 mb-4">
                                    <div class="ctn-eng" style={{ "width": "310px" }}>
                                        <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.11")}</h3>
                                        <p>{t("atuacao.text-8.12")}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-eq-height" style={{ "justifyContent": "center", "width": "90px" }}>
                                <div class="col-4 mb-4">
                                    <div class="ctn-eng" style={{ "width": "310px" }}>
                                        <h3 style={{ "textAlign": "start", "fontSize": "15px" }}>{t("atuacao.text-8.13")}</h3>
                                        <p>{t("atuacao.text-8.14")}</p></div>
                                </div>
                            </div>
                        </div>}

                </div>
            </section>
            <ImageAndTitle imageByClassName={"section-intro-page bg-redes-ip"} />
            <section class="section-sub-page " id="redesip">
                <div class="container">
                    <div class="row">
                        <div class="col-13">
                            <h2>{t("atuacao.links-2.2")}</h2>
                            <p style={{ "paddingRight": "20px", "paddingLeft": "10px" }}>{t("atuacao.text-9.1")}
                                <br /><br />
                                {t("atuacao.text-9.2")}
                                <br /><br />
                                {t("atuacao.text-9.3")}
                                <br /><br />
                            </p>
                            {!isMobile
                                ? <div class="row my-3">
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.4")} </h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.5")};</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "30px" }}>
                                                {t("atuacao.text-9.6")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>{t("atuacao.text-9.7")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <div class="row my-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "60px" }}>{t("atuacao.text-9.4")} </h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "50px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.5")};</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "45px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.6")}</h3>
                                        </div>
                                    </div>
                                </div>}

                            {!isMobile
                                ? <div class="row my-3">
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "50px" }}>High Level Design (HLD);</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "50px" }}>Low Level Design (LLD);</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>Method of Procedure Template (MoP-T);</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "50px" }}>Method of Procedure (MoP);</h3>
                                        </div>
                                    </div>
                                </div>
                                : <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px" }}>{t("atuacao.text-9.7")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px" }}>{t("atuacao.text-9.4")} </h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "50px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.5")}</h3>
                                        </div>
                                    </div>
                                </div>}

                            {!isMobile
                                ? <div class="row mb-3">
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "50px" }}>{t("atuacao.text-9.8")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>{t("atuacao.text-9.9")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.6")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px" }}>{t("atuacao.text-9.7")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "60px" }}>{t("atuacao.text-9.8")}</h3>
                                        </div>
                                    </div>
                                </div>}

                            {isMobile
                                ? <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.9")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <></>}

                            <p style={{ "paddingTop": "20px", "paddingRight": "20px", "paddingLeft": "10px" }}>{t("atuacao.text-9.10")}<br /><br /></p>

                            {!isMobile
                                ? <div class="row mb-3">
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "30px" }}>
                                                {t("atuacao.text-9.11")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.12")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "35px" }}>
                                                {t("atuacao.text-9.13")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.14")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "35px" }}>{t("atuacao.text-9.11")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px" }}> {t("atuacao.text-9.12")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.13")}</h3>
                                        </div>
                                    </div>
                                </div>}

                            {!isMobile
                                ? <div class="row mb-3">
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.15")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.16")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "50px" }}> {t("atuacao.text-9.17")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "45px" }}>
                                                {t("atuacao.text-9.14")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.15")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px" }}> {t("atuacao.text-9.16")}</h3>
                                        </div>
                                    </div>
                                </div>}

                            {isMobile
                                ? <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "70px" }}> {t("atuacao.text-9.17")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <></>}

                            <p style={{ "paddingRight": "20px", "paddingLeft": "10px" }}>
                                <br /><br />
                                {t("atuacao.text-9.18")}
                                <br /><br />
                            </p>

                            {!isMobile
                                ? <div class="row mb-3">
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "25px" }}>
                                                {t("atuacao.text-9.19")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.20")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "5px" }}>
                                                {t("atuacao.text-9.21")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3>{t("atuacao.text-9.22")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3>{t("atuacao.text-9.19")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.20")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "3px" }}>{t("atuacao.text-9.21")}</h3>                        </div>
                                    </div>
                                </div>}

                            {!isMobile
                                ? <div class="row mb-3">
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.23")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.24")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "40px" }}>
                                                {t("atuacao.text-9.25")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingLeft": "5px", "paddingRight": "5px" }}>{t("atuacao.text-9.22")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.23")}</h3>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "45px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.24")}</h3>
                                        </div>
                                    </div>
                                </div>}

                            {isMobile
                                ? <div class="row mb-3" style={{ "paddingRight": '55px', "justifyContent": "space-between" }}>
                                    <div class="col-3">
                                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                            <h3 style={{ "paddingTop": "55px", "paddingLeft": "5px", "paddingRight": "5px" }}>
                                                {t("atuacao.text-9.25")}</h3>
                                        </div>
                                    </div>
                                </div>
                                : <></>}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Engineering