import React from "react";
import { Route, BrowserRouter, Switch} from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Fitec from "../pages/Fitec";
import Home from "../pages/Home";
import Solucao from "../pages/Solutions";
import Engineering from "../pages/Performance/Engineering";
import Inovation from "../pages/Performance/Inovation";
import AboutFitec from "../pages/AboutFitec";
import ContactUs from '../pages/Contact/ContactUs';
import Posts from '../pages/Posts';
import WorkWithUs from '../pages/Contact/WorkWithUs'
const Routes = () => {
   return(
       <BrowserRouter>
           <Header/> 
                <Switch>
                    <Route component={ Home } path="/" exact />
                    <Route component = { Fitec }  path="/fitec" />
                    <Route component= { Engineering } path ="/engenharia" />
                    <Route component= { Inovation } path ="/inovacao" />
                    <Route component = { Solucao }  path="/solucoes" />
                    <Route component = { WorkWithUs }  path="/oportunidade" />
                    <Route component= { ContactUs } path = "/contato" />
                    <Route component= { Posts } path = "/posts" />
                    <Route component= { AboutFitec } path = "/sobre" />
                </Switch>
           <Footer/>
       </BrowserRouter>
   )
}

export default Routes;