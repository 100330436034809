import logo_lei_informatica from "../../../assets/images/fomento/Site-Logo-Lei-da-Informatica.png"
import logo_aneel from "../../../assets/images/fomento/Site-Logo-ANEEL-1.png"
import logo_finep from "../../../assets/images/fomento/Site-Logo-Finep-1.png"
import logo_lei_bem from "../../../assets/images/fomento/Site-Logo-Lei-do-Bem.png"
import logo_softex from "../../../assets/images/fomento/Site-Logo-Softex.png"
import logo_anp from "../../../assets/images/fomento/Site-Logo-ANP-1.png"
import logo_fapesp from "../../../assets/images/fomento/Site-Logo-FAPESP.png"
import logo_mcti from "../../../assets/images/fomento/mcti.jpg"
import logo_bnds from "../../../assets/images/fomento/bnds.jpg"
import logo_facepe from "../../../assets/images/fomento/facepe.jpg"
import logo_rota from "../../../assets/images/fomento/Site-Logo-Rota-2030.png"

function SectionFomentobox() {
    return (
        <div class="row mt-5">

            <div class="col-3 mb-4"><img src={logo_lei_informatica} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_aneel} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_finep} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_lei_bem} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_softex} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_anp} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_fapesp} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_mcti} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_bnds} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_facepe} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

            <div class="col-3 mb-4"><img src={logo_rota} alt="" style={{ width: 360 }} class="img-fluid wow fadeIn" data-wow-delay="0.4s" /></div>

        </div>
    );
}

export default SectionFomentobox