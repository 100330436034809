import React from "react";
import "../css/main.css";
import { useMediaQuery } from "react-responsive";
import SectionFomentobox from "./box-logos/box";
import SectionBox from "./box-logos/box_responsive";
import { useTranslation } from "react-i18next";

export default function SectionFomento() {

    const {t} = useTranslation();
    const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    return (
        <section class="section section-fomento bg-cinza-1">

            <div class="container">

                <div class="row mb-2">

                    <div class="col " style={{display: "flex" , justifyContent: "center", alignItems: "center",  flexDirection: "column"}}>

                        <div>
                            <h2 class="wow fadeIn" data-wow-delay="0.4s">{t("home.fomentos")}</h2>
                        </div>

                        <div>
                            <h6 style={{textAlign: "center", fontSize:"18px", marginLeft:"15px"}}>{t("home.subtitle")}</h6>
                        </div>
                    </div>

                </div>

                {!isMobile && <SectionFomentobox />}
                {isMobile && <SectionBox />}

            </div>

        </section>
    );
}