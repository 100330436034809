import React from 'react'
import './TitleAndText.css'

function TitleAndText ({title, content}) {
    return <div>
                <h2>
                    {title}
                </h2>
                <p>
                    {content}
                </p>
            </div>
}

export default TitleAndText
