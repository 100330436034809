import React from "react";
import "../css/main.css";
import "./style.css";
import "../css/owl_carousel/owl.carousel.css";
import "../css/owl_carousel/owl.theme.default.css";
import ReactOwlCarousel from "react-owl-carousel";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";


export default function SectionAtuacao() {

    const { t } = useTranslation();

    return (
        <section class="section-atuacao">

            <div class="container">

                <div class="row">
                    <div class="col-12">
                        <h2 class="wow fadeIn" data-wow-delay="0.4s">{t("menu.atuacao")}</h2>
                    </div>
                </div>

                <div class="row">

                    <div class="col-12">
                        <ReactOwlCarousel className="owl-theme" items={1} margin={10} nav={true}
                            navText={['<span class="arrow prev">‹</span>', '<span class="arrow next">›</span>']}
                            data-wow-delay="0.4s">
                            <div class="item">
                                <div class="col">
                                    <div class="ctn-atuacao">
                                        <h3>{t("atuacao.pd")}</h3>

                                        <p>{t("atuacao.text-1.1")}
                                            <br />
                                            <br />
                                            {t("atuacao.text-1.2")}


                                            <br />
                                            <br />

                                            {t("atuacao.text-1.3")}

                                        </p>


                                        <div class="row mt-4">

                                            <div class="col-12 text-center links-atuacao">

                                                <HashLink to="/inovacao#software">
                                                    {t("atuacao.links.1")}</HashLink>

                                                <HashLink to="/inovacao#equipamentos-eletronicos">
                                                    {t("atuacao.links.2")}</HashLink>

                                                <HashLink to="/inovacao#IA">{t("atuacao.links.3")}</HashLink>

                                                <HashLink to="/inovacao#IOT">{t("atuacao.links.4")}</HashLink>

                                                <HashLink to="/inovacao#transformacao-digital">
                                                {t("atuacao.links.5")}</HashLink>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div class="item">
                                <div class="col">

                                    <div class="ctn-atuacao">

                                        <h3>{t("atuacao.engenharia")}</h3>

                                        <p>
                                            {t("atuacao.text-7.1")}
                                            {/* <br />
                                            {t("home.atuacao.5")} */}
                                            
                                        </p>


                                        <div class="row mt-4">

                                            <div class="col-12 text-center links-atuacao">

                                                <a href="?url=inovacao#redescelulares">{t("atuacao.links-2.1")}</a>

                                                <a href="?url=inovacao#redesip">{t("atuacao.links-2.2")}</a>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>

        </section>
    );
}