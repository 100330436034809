import React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import "../css/main.css";
import "./style.css";

import logoHeader from "../../assets/images/logo-header.svg";
import lngbr from "../../assets/images/lng-br.svg";
import lngen from "../../assets/images/lng-en.svg";
import $ from 'jquery';
import { useMediaQuery } from "react-responsive";


export default function Header() {

    const { t, i18n } = useTranslation();

    const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });

    useEffect(() => {

        // i18n.changeLanguage('pt');

        var isOpen = false;
        $('.click-translate').on('click', function (event) {

            if (isOpen) {
                $('.lng').fadeOut(200);
            } else {

                $('.lng').fadeIn(200);
            }

            isOpen = !isOpen;
        });

        $('.language').on('click', function (event) {
            var img = $(event.target).attr('src');
            console.log($(event.target));
            $('.click-translate').attr('src', img);
            $('.lng').fadeOut(200);
        });

        $('.dropdown').hover(function () {
            $(this).find('.dropdown-menu').stop(true, true).delay(200).slideDown(200);
        }, function () {
            $(this).find('.dropdown-menu').stop(true, true).delay(200).slideUp(200);
        });

    });


    function ChangeLang(lang) {
        i18n.changeLanguage(lang);
    }

    return (
        <>
            <div className="header-fitec" style={{ display: "flex" }}>
                <div className="container-fluid px-0">
                    <nav className="navbar navbar-expand-lg">
                        <Link className="navbar-brand" to="/">
                            <div className="logo">
                                <img src={logoHeader} alt="" className="img-fluid" />
                            </div>
                        </Link>
                        {/* <a className="navbar-brand" href="?url=home">
                        <div className="logo">
                            <img src={logoHeader} alt="" className="img-fluid" />
                        </div>
                    </a> */}
                        <button className="navbar-toggler bt-menu" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                                <img src="src/img/menu.svg" alt="" className="img-fluid" />
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="menu nav navbar-nav mx-auto">
                                <li class="nav-item"><HashLink class="nav-link" to="/#inicio" style={{fontSize:"11px"}}>{t('menu.sobre')}</HashLink></li>
                                <li class="nav-item"><HashLink class="nav-link" to="/inovacao#inicio-inovacao" style={{fontSize:"11px"}}>{t('atuacao.pd')}</HashLink></li>
                                <li class="nav-item"><HashLink class="nav-link" to="/engenharia#inicio-engenharia" style={{fontSize:"11px"}}>{t('atuacao.engenharia')}</HashLink></li>
                                <li class="nav-item"><HashLink class="nav-link" to="/solucoes#sustentabilidade" style={{fontSize:"11px"}}>{t('menu.solucao')}</HashLink></li>
                                <li class="nav-item"><HashLink class="nav-link" to="/posts#inicio-publicacoes" style={{fontSize:"11px"}}>{t('menu.publicacoes')}</HashLink></li>
                                <li class="nav-item"><HashLink class="nav-link" to="/oportunidade#trabalhe-conosco" style={{fontSize:"11px"}}>{t('menu.oportunidades')}</HashLink></li>
                                <li class="nav-item"><HashLink class="nav-link" to="/#contato" style={{fontSize:"11px"}}>{t("menu.contato")}</HashLink></li>
                                {/* <li className="nav-item dropdown position-static">
                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                                        aria-haspopup="true" aria-expanded="false">{t('menu.sobre')}</a>
                                    <ul className="dropdown-menu text-center" role="menu" style={{ backgroundColor: 'rgba(48, 48, 48, 0.438)' }}>
                                        <li><Link className="dropdown-item page-scroll" to="/sobre">{t('sobre.sobre')}</Link></li>
                                        <li><HashLink className="dropdown-item page-scroll" to="/sobre#governanca">{t('sobre.governanca')}</HashLink></li>
                                        <li><HashLink className="dropdown-item page-scroll"
                                            to="/sobre#responsabilidade-social">{t('sobre.social')}</HashLink></li>
                                        <li><HashLink className="dropdown-item page-scroll"
                                            to="/sobre#sustentabilidade">{t('sobre.sustentabilidade')}</HashLink></li>
                                    </ul>
                                </li> */}
                                {/* <li className="nav-item dropdown position-static">
                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                                        aria-haspopup="true" aria-expanded="false"><span className="text-uppercase">{t("menu.atuacao")}</span></a>
                                    <ul className="dropdown-menu text-center" role="menu" style={{ backgroundColor: 'rgba(48, 48, 48, 0.438)' }}>
                                        <li><Link className="dropdown-item" to="/inovacao">{t('atuacao.pd')}</Link></li>
                                        <li><Link className="dropdown-item" to="/engenharia">{t('atuacao.engenharia')}</Link></li>
                                    </ul>
                                </li>

                                <li className="nav-item dropdown position-static">
                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                                        aria-haspopup="true" aria-expanded="false">{t("menu.solucao")}</a>
                                    <ul className="dropdown-menu text-center" role="menu" style={{ backgroundColor: 'rgba(48, 48, 48, 0.438)' }}>
                                        <li>
                                            <HashLink className="dropdown-item" to="/solucoes#transformacao-digital">{t('solucao.digital')}</HashLink>
                                        </li>
                                        <li>
                                            <HashLink className="dropdown-item" to="/solucoes#IOT">{t('solucao.iot')}</HashLink>
                                        </li>
                                        <li>
                                            <HashLink className="dropdown-item" to="/solucoes#EngenhariaDeRedesIP">{t('solucao.redeIP')}</HashLink>
                                        </li>
                                        <li>
                                            <HashLink className="dropdown-item" to="/solucoes#MicroredesDeEnergia">{t('solucao.energia')}</HashLink>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item"><Link className="nav-link page-scroll animation"
                                    to="/posts">{t("menu.publicacoes")}</Link></li>

                                <li className="nav-item dropdown position-static">
                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button"
                                        aria-haspopup="true" aria-expanded="false">{t("menu.contato")}</a>
                                    <ul className="dropdown-menu text-center" role="menu" style={{ backgroundColor: 'rgba(48, 48, 48, 0.438)' }}>
                                        <li><HashLink className="dropdown-item" to="/#contato">{t('contato.faleConosco')}</HashLink></li>
                                        <li><Link className="dropdown-item" to="/oportunidade">{t('contato.oportunidades')}</Link></li>
                                    </ul>
                                </li> */}
                            </ul>

                            {!isMobile &&
                                <div className="linguagem">
                                    <img src={lngbr} alt="" className='click-translate animation' />
                                    <ul className="lng animation">
                                        <li>
                                            <a href="javascript:void(0)" className="pt" onClick={() => ChangeLang('pt')}
                                                title="Traduzir para portugu&ecirc;s">
                                                <img src={lngbr} className="language animation" border="0" alt="Portugues" />
                                            </a>
                                        </li>

                                        <li>
                                            <a href="javascript:void(0)" className="en" onClick={() => ChangeLang('en')}
                                                title="Translate to English">
                                                <img src={lngen} className="language animation" border="0" alt="English" />
                                            </a>
                                        </li>

                                    </ul>
                                </div>}

                        </div>


                    </nav>

                </div>

                {isMobile &&
                    <div className="linguagem">
                        <img src={lngbr} alt="" className='click-translate animation' />
                        <ul className="lng animation">
                            <li>
                                <a href="javascript:void(0)" className="pt" onClick={() => ChangeLang('pt')}
                                    title="Traduzir para portugu&ecirc;s">
                                    <img src={lngbr} className="language animation" border="0" alt="Portugues" />
                                </a>
                            </li>

                            <li>
                                <a href="javascript:void(0)" className="en" onClick={() => ChangeLang('en')}
                                    title="Translate to English">
                                    <img src={lngen} className="language animation" border="0" alt="English" />
                                </a>
                            </li>

                        </ul>
                    </div>}
            </div>


        </>
    );
}

