import React from 'react'
import ImageAndTitle from '../../../components/ImageAndTitle'
import { useMediaQuery } from 'react-responsive'
import './style.css'
import { useTranslation } from 'react-i18next';

function Inovation() {
    const {t} = useTranslation();
    const isMobile = useMediaQuery({query: "(max-width: 1224px)"})

  return (
    <>
        <section class="section-intro-page bg-inovacao" id='inicio-inovacao'>
            <div class="container h-100">
                <div class="row h-100 align-items-center">
                    <div class="col-12 text-center">
                        <h1>{t("atuacao.pd")}</h1>
                        <ul class="submenu-interno mt-4">
                            <li><a class="page-scroll" href="#software">{t("atuacao.links.1")}</a></li>
                            <li><a class="page-scroll" href="#equipamentos-eletronicos">{t("atuacao.links.2")}</a></li>
                            <li><a class="page-scroll" href="#IA">{t("atuacao.links.3")}</a></li>
                            <li><a class="page-scroll" href="#IOT">{t("atuacao.links.4")}</a></li>
                            <li><a class="page-scroll" href="#transformacao-digital">{t("atuacao.links.5")}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-sub-page bg-cinza-1" id="inovacao">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <p style={{"paddingRight": "15px", "paddingLeft": "10px"}}>
                            {t("atuacao.text-1.1")}
                            <br/><br/>
                            {t("atuacao.text-1.2")}
                            <br/><br/>
                            {t("atuacao.text-1.3")}
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <ImageAndTitle imageByClassName= "section-intro-page bg-software"/>
        <section class="section-sub-page" id="software">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2> {t("atuacao.links.1")}</h2>
                        <p style={{"paddingRight": "15px", "paddingLeft": "5px"}}>
                            {t("atuacao.text-1.4")}
                            </p>
                        {!isMobile 
                        ? <div class="row my-3">
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-2.1")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-2.2")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-2.3")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-2.9")}</h3>
                                </div>
                            </div>
                        </div> 
                        : 
                        <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "70px"}}>{t("atuacao.text-2.1")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "70px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                    {t("atuacao.text-2.2")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "70px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                    {t("atuacao.text-2.3")}</h3>
                                </div>
                            </div>
                        </div>}

                        {!isMobile 
                        ? <div class="row my-3">
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-2.4")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-2.5")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-2.6")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-2.7")}</h3>
                                </div>
                            </div>
                        </div> 
                        : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "70px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                        {t("atuacao.text-2.3")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "55px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                    {t("atuacao.text-2.4")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "55px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                    {t("atuacao.text-2.5")}</h3>
                                </div>
                            </div>
                        </div>}

                        {!isMobile 
                        ? <div class="row my-3">
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-2.8")}</h3>
                                </div>
                            </div>
                        </div> 
                        : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>{t("atuacao.text-2.6")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>{t("atuacao.text-2.7")}</h3>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>{t("atuacao.text-2.8")}</h3>
                                </div>
                            </div>
                            
                    </div> }
                    {!isMobile 
                    ? <></> 
                    : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                        <div class="col-3">
                                <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                                    <h3 style={{"paddingTop": "55px", "paddingLeft": "5px", "paddingRight": "5px"}}>{t("atuacao.text-2.9")}</h3>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </section>
        <ImageAndTitle imageByClassName="section-intro-page bg-eletronicos"/>
        <section class="section-sub-page bg-cinza-1" id="equipamentos-eletronicos">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>{t("atuacao.links.2-1")}</h2>
                        <p style={{"paddingRight": "15px", "paddingLeft": "10px"}}>
                            {t("atuacao.text-3.1")}
                        </p>
                    </div>
                </div>
                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>DFMP – Design for Manufacturing</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px"}}>Layout for Manufacturing</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px"}}>DFT – Design for Testing</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px"}}>ICT – In-Circuit Test</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                DFMP – Design for Manufacturing</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                Layout for Manufacturing</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                DFT – Design for Testing</h3>
                        </div>
                    </div>                   
                </div>}
                
                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>AOI – Automatic Optical Inspection</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>AXI – Automatic X-ray Inspection</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3>{t("atuacao.text-3.2")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-3.3")}</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                        <div class="col-3">
                            <div class="ctn-rounded ctn-inovacao bg-white">
                                <h3 style={{"paddingTop": "70px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                    ICT – In-Circuit Test</h3>
                            </div>
                        </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "65px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                AOI – Automatic Optical Inspection</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "65px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                AXI – Automatic X-ray Inspection</h3>
                        </div>
                    </div>
                </div>}
                
                {isMobile 
                ? <div class = "row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "20px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                            {t("atuacao.text-3.2")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                            {t("atuacao.text-3.3")}</h3>
                        </div>
                    </div>
                </div> : <></>}

                <div class="row">
                    <div class="col-12">
                        <p style={{"paddingRight": "15px", "paddingLeft": "10px", "paddingTop": "15px"}}>
                            {t("atuacao.text-3.4")}
                            <br/><br/>
                        </p>
                        <h3 style={{
                            "textAlign":"start", 
                            "fontSize":"15px",
                            "paddingRight": "20px", "paddingLeft": "10px"
                            }}>{t("atuacao.text-3.5")}</h3>
                    </div>
                </div>

                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-3.6")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-3.7")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-3.8")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px"}}>{t("atucao.text-3.9")}</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.6")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.7")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.8")}</h3>
                        </div>
                    </div>
                </div>}

                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-3.10")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-3.11")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-3.12")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-3.13")}</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atucao.text-3.9")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.10")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.11")}</h3>
                        </div>
                    </div>
                </div>}

                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px"}}>{t("atuacao.text-3.14")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "25px"}}>
                                {t("atuacao.text-3.15")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-3.16")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "30px"}}>
                                {t("atuacao.text-3.17")}</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.12")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.13")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "50px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.14")}</h3>
                        </div>
                    </div>
                </div>}
                
                {isMobile 
                ? <div class ="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "30px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.15")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.16")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-white">
                            <h3 style={{"paddingTop": "40px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-3.17")}</h3>
                        </div>
                    </div>
                </div>
                :<></>}

            </div>
        </section>
        <ImageAndTitle imageByClassName= "section-intro-page bg-ia"/>
        <section class="section-sub-page" id="IA">
            <div class="container">
                <div class="row mb-3">
                    <div class="col-12">
                        <h2>{t("atuacao.links.3")}</h2>
                        <p style={{"paddingRight": "15px", "paddingLeft": "10px"}}>{t("atuacao.text-4.1")}</p>
                        <br/><br/>
                        <h3 class="mb-3" style={{
                            "textAlign":"start", 
                            "fontSize":"15px",
                            "paddingRight": "15px", "paddingLeft": "10px"
                            }}>{t("atuacao.text-4.2")}</h3>


                        <h5 style={{
                            "paddingBottom":"0",
                            "paddingRight": "15px",
                            "paddingLeft": "10px"
                        }}>{t("atuacao.text-4.3")}</h5>
                        <h5 style={{
                            "paddingBottom":"0",
                            "paddingRight": "15px",
                            "paddingLeft": "10px"
                        }}>{t("atuacao.text-4.4")}</h5>
                        <h5 style={{
                            "paddingBottom":"0",
                            "paddingRight": "15px",
                            "paddingLeft": "10px"
                        }}>{t("atuacao.text-4.5")}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h3 style={{
                            "textAlign":"start", 
                            "fontSize":"15px",
                            "paddingRight": "15px", "paddingLeft": "10px"
                            }}>{t("atuacao.text-4.6")}</h3>
                    </div>
                </div>
                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-4.7")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-4.8")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-4.9")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "50px"}}>UX (User Experience)</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "65px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-4.7")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "70px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-4.8")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "70px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-4.9")}</h3>
                        </div>
                    </div>
                </div>}

                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-4.10")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "30px"}}>
                                {t("atuacao.text-4.11")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "50px"}}>{t("atuacao.text-4.12")}</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                UX (User Experience)</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "60px", "paddingLeft": "10px", "paddingRight": "10px"}}>
                                {t("atuacao.text-4.10")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "40px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-4.11")}</h3>
                        </div>
                    </div>
                </div>}

                {isMobile 
                ? <div class = 'row my-3' style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "70px"}}>{t("atuacao.text-4.12")}</h3>
                        </div>
                    </div>
                </div> 
                : <></>}

                <div class="row">
                    <div class="col-12">
                        <h3 style={{
                            "textAlign":"start", 
                            "fontSize":"15px",
                            "paddingRight": "15px", "paddingLeft": "10px","paddingTop": "10px",
                            }}>{t("atuacao.text-4.13")}</h3>
                    </div>
                </div>

                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "30px"}}>
                                {t("atuacao.text-4.14")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "30px"}}>
                                {t("atuacao.text-4.15")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "40px"}}>
                                {t("atuacao.text-4.16")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingLeft": "5px", "paddingRight":"5px"}}>
                               {t("atuacao.text-4.17")}</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "45px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-4.14")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "45px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-4.15")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "50px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao.text-4.16")}</h3>
                        </div>
                    </div>
                </div>}

                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "25px"}}>
                                {t("atuacao")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "5px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                               {t("atuacao.text-4.19")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "30px"}}>
                               {t("atuacao.text-4.20")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "15px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                               {t("atuacao.text-4.21")}</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "10px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                               {t("atuacao.text-4.17")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "25px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                                {t("atuacao")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop":"12px"}}>
                               {t("atuacao.text-4.19")}</h3>
                        </div>
                    </div>
                </div>}

                {!isMobile 
                ? <div class="row my-3">
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "30px"}}>
                               {t("atuacao.text-4.22")}</h3>
                        </div>
                    </div>
                </div>
                : <div class="row my-3" style={{"paddingRight": '55px', "justifyContent": "space-between"}}>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "35px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                               {t("atuacao.text-4.20")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "10px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                               {t("atuacao.text-4.21")}</h3>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="ctn-rounded ctn-inovacao bg-cinza-1">
                            <h3 style={{"paddingTop": "35px", "paddingLeft": "5px", "paddingRight": "5px"}}>
                               {t("atuacao.text-4.22")}</h3>
                        </div>
                    </div>
                </div>}

            </div>
        </section>
        <ImageAndTitle imageByClassName= "section-intro-page bg-iot-inovacao"/>
        <section class="section-sub-page bg-cinza-1" id="IOT">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>{t("atuacao.links.4")}</h2>
                        <p style={{"paddingRight": "15px", "paddingLeft": "15px"}}>
                            {t("atuacao.text-5.1")}
                            <br/><br/>
                            {t("atuacao.text-5.2")}
                            <br/><br/>
                            {t("atuacao.text-5.3")}
                            <br/><br/>
                            {t("atuacao.text-5.4")}
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <ImageAndTitle imageByClassName= "section-intro-page bg-td"/>
        <section class="section-sub-page" id="transformacao-digital">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>{t("atuacao.links.5")}</h2>
                        <p style={{"paddingRight": "15px", "paddingLeft": "15px"}}>
                            {t("atuacao.text-6.1")}
                            <br/><br/>
                            {t("atuacao.text-6.2")}
                            <br/><br/>
                            {t("atuacao.text-6.3")}
                           </p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Inovation