import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import "../../components/css/main.css";
import "../../components/css/animate.css";
import mulherCelular from "../../assets/images/img_mulhercelular.jpg";
import IntroHome from "../../components/IntroHome";
import SectionAtuacao from "../../components/SectionAtuacao";
import SectionSolucoes from "../../components/SectionSolucoe";
import SectionAmbiente from "../../components/SectionAmbient";
import SectionOportunidades from "../../components/SectionOportunidades";
import SectionClient from "../../components/SectionClient";
import SectionFomento from "../../components/SectionFomento";
import SectionContato from "../../components/SectionContato";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

export default function Home() {

    const { t } = useTranslation();
    const [isHover, setIsHover] = useState(false);
    const handleMouseEnter = () => {
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        setIsHover(false);
    };
    const isMobile = useMediaQuery({ query: "(max-width: 1224px)" })
    const buttonStyle = {
        color: !isHover ? "rgb(255, 255, 255)" : "#004e77",
        background: !isHover ? "#004e77" : "rgb(82, 134, 183)",
        marginLeft: !isMobile ? "180px" : "90px",
        paddingBottom:!isMobile ?"0":"", 
        paddingTop:!isMobile ?"0":"", 
    }

    return (
        <>
            <IntroHome />

            <section className="section-sobre" style={{paddingBottom:!isMobile ?"20px":"", }}>

                {!isMobile && 
                
                <div className="container" >

                    <div className="row" style={{display: "block", width: "50%",float : "right"}}>

                        <div className="col-mb" style={{marginLeft:"30px"}}>

                            <div className="col-10">
                                <h2 className="wow fadeIn" data-wow-delay="0.4s" style={{marginLeft:"90px"}}>{t("Fitec.1")}</h2>
                            </div>
                            <p className="wow fadeIn" data-wow-delay="0.4s">
                                {t("sobre.text-1.1")}
                            </p>

                            <p className="wow fadeIn" data-wow-delay="0.4s">
                                {t("sobre.text-1.2")}
                            </p>

                            <p className="wow fadeIn" data-wow-delay="0.4s">
                                {t("sobre.text-1.3")}
                            </p>

                            <HashLink
                                className="my-4 animation link wow fadeIn"
                                data-wow-delay="0.4s" to="/sobre#a-fitec"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                style={buttonStyle}>

                                <p style={{ color:"white" }}>{t("home.home")}</p></HashLink>
                        </div >
                    </div>

                </div >}

                {isMobile && <div className="container">

                    <div className="row">
                        <div className="col-12">
                            <h2 className="wow fadeIn" data-wow-delay="0.4s">{t("Fitec.1")}</h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-5">
                            <img src={mulherCelular} alt="" width="100%" className="mb-3 img-fluid wow fadeInLeft"
                                data-wow-delay="0.4s" />
                        </div>

                        <div className="col-sm">
                            <p className="wow fadeIn" data-wow-delay="0.4s">
                                {t("sobre.text-1.1")}
                            </p>

                            <p className="wow fadeIn" data-wow-delay="0.4s">
                                {t("sobre.text-1.2")}
                            </p>

                            <p className="wow fadeIn" data-wow-delay="0.4s">
                                {t("sobre.text-1.3")}
                            </p>

                            <HashLink
                                className="my-4 animation link wow fadeIn"
                                data-wow-delay="0.4s" to="/sobre#a-fitec"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                style= {buttonStyle}>
                            <p style={{color:"white"}}>{t("home.home")}</p></HashLink>


                        </div >
                    </div >
                </div >}


            </section >

            <SectionAtuacao />

            <SectionSolucoes />

            <SectionAmbiente />

            <SectionOportunidades />

            <SectionClient />

            <SectionFomento />

            <SectionContato />

        </>
    );
}