import React from "react";
import "../../css/main.css";

import alfamed from "../../../assets/images/cliente/alfamed.png";
import algar from "../../../assets/images/cliente/algar.png";
import bosch from "../../../assets/images/cliente/bosch.png";
import celpa from "../../../assets/images/cliente/celpa.png";
import cemar from "../../../assets/images/cliente/cemar.png";

import cemig from "../../../assets/images/cliente/cemigColor.png";
import chesf from "../../../assets/images/cliente/chesfColor.png";
import ciena from "../../../assets/images/cliente/ciena.png";
import cisco from "../../../assets/images/cliente/cisco.png";
import clamper from "../../../assets/images/cliente/clamper.png";

import claro from "../../../assets/images/cliente/claro.png";
import cm from "../../../assets/images/cliente/cm.png";
import constanta from "../../../assets/images/cliente/constanta.png";
import dpr from "../../../assets/images/cliente/dpr.png";
import elektro from "../../../assets/images/cliente/elektro.png";

import eletrobras from "../../../assets/images/cliente/eletrobras.png";
import eletro_paulo from "../../../assets/images/cliente/eletropaulo.png";
import enel from "../../../assets/images/cliente/enel.png";
import energisa from "../../../assets/images/cliente/energisa.png";
import equatorial from "../../../assets/images/cliente/equatorial02.png";

import equatorial_energia from "../../../assets/images/cliente/equatorial.png";
import ericsson from "../../../assets/images/cliente/ericssonColor.png";
import furukawa from "../../../assets/images/cliente/furukawa.png";
import ge from "../../../assets/images/cliente/ge.png";
import huawei from "../../../assets/images/cliente/huaweiColor.png";

import iberola from "../../../assets/images/cliente/iberdola.png";
import ingeteam from "../../../assets/images/cliente/ingeteam.png";
import multilaser from "../../../assets/images/cliente/multilaser.png";
import nokia from "../../../assets/images/cliente/nokia.png";
import oi from "../../../assets/images/cliente/oi.png";

import philips from "../../../assets/images/cliente/philips.png";
import positivo from "../../../assets/images/cliente/positivo.png";
import sap from "../../../assets/images/cliente/sap.png";
import senai from "../../../assets/images/cliente/senaiColor.png";
import siemens from "../../../assets/images/cliente/siemens.png";

import tim from "../../../assets/images/cliente/tim.png";
import tury from "../../../assets/images/cliente/tury.png";
import vivo from "../../../assets/images/cliente/vivo.png";
import vmi from "../../../assets/images/cliente/vmi_medica.png";
import vmi_security from "../../../assets/images/cliente/vmi_security.png";

import lacerda from "../../../assets/images/cliente/lacerda.png";
import vyaire from "../../../assets/images/cliente/vyaire.png";




export default function SectionBox() {

    return (

        <>
        <div class="row mt-4" id="item" style={{padding:"25px"}}>
            <div class="col" style={{ display: "flex", alignItems: "center" }}><img src={alfamed} alt="Alfamed" class="mx-auto" style={{ width: 180, height: 65 }} /></div>
            <div class="col" style={{ display: "flex", alignItems: "center" }}><img src={algar} alt="Algar" class="mx-auto" style={{ width: 180, height: 65 }} /></div>
            <div class="col" style={{ display: "flex", alignItems: "center" }}><img src={bosch} alt="Bosch" class="mx-auto" style={{ width: 180, height: 65 }} /></div>
            <div class="col" style={{ display: "flex", alignItems: "center" }}>
                <img src={celpa} alt="Celpa" class="mx-auto" style={{ width: 180, height: 65 }} />
            </div>
            <div class="col" style={{ display: "flex", alignItems: "center" }}>
                <img src={cemar} alt="Cemar" class="mx-auto" style={{ width: 180, height: 65 }} />
            </div>
            <div class="col" style={{ display: "flex", alignItems: "center" }}>
                <img src={cemig} alt="Cemig" class="mx-auto" style={{ width: 180, height: 65 }} />
            </div>
            
        </div>


            <div class="row mt-3" id="item" style={{padding:"25px"}}>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={chesf} alt="Chesf" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={ciena} alt="Ciena" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={cisco} alt="Cisco" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={clamper} alt="Clamper" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={claro} alt="Claro" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={cm} alt="CM Comandos" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
            </div>

            <div class="row mt-3" id="item" style={{padding:"25px"}}>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={constanta} alt="Costanta" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={dpr} alt="DPR" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={elektro} alt="Elektro" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={eletrobras} alt="Eletrobras" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={eletro_paulo} alt="Eletropaulo" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={enel} alt="Enel" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
            </div>


            <div class="row mt-3" id="item" style={{padding:"25px"}}>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={energisa} alt="Energisa" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={equatorial} alt="Equatorial" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={equatorial_energia} alt="Equatorial" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={ericsson} alt="Ericsson" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={furukawa} alt="Furukawa" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={ge} alt="GE Healthcare" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
            </div>


            <div class="row mt-3" id="item" style={{padding:"25px"}}>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={huawei} alt="Huawei" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={iberola} alt="Iberdola" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={ingeteam} alt="Ingeteam" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={lacerda} alt="Lacerda" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={multilaser} alt="Multilaser" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={nokia} alt="Nokia" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
            </div>


            <div class="row mt-3" id="item" style={{padding:"25px"}}>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={oi} alt="OI" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={philips} alt="Philips" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={positivo} alt="Positivo" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={sap} alt="SAP" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={senai} alt="Senai" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={siemens} alt="Siemens" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
            </div>


            <div class="row mt-3" id="item" style={{padding:"25px"}}>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={tim} alt="TIM" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={tury} alt="Tury" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={vivo} alt="VIVO" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={vmi} alt="VMI Medica" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={vmi_security} alt="VMI Security" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
                <div class="col" style={{ display: "flex", alignItems: "center" }}>
                    <img src={vyaire} alt="Vyaire" class="mx-auto" style={{ width: 180, height: 65 }} />
                </div>
            </div>
        </>
    );
}