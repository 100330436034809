import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import privacidade from "../../assets/pdf/privacidade.pdf"
import "../css/main.css";


export default function SectionOportunidades() {
    const {t} = useTranslation();
    const isMobile = useMediaQuery({query : "(max-width: 1224px)"})
    const buttonStyle = {
        marginLeft: !isMobile ? "42.5%" : "60px",
    }

    return (
        <section class="section-oportunidades pb-0" style={{paddingTop: !isMobile ?  "180px" : "90px"}}>

            <div class="container">

                <div class="row">

                    <div class="col">

                        <h2 class="wow fadeIn" data-wow-delay="0.4s">{t("contato.oportunidades")}</h2>

                    </div>

                </div>

            </div>
            <div class="bg-oportunidades">


                <div class="container">

                    <div class="row">

                        <div class="col-12">

                            <p class="wow fadeInLeft" data-wow-delay="0.4s">
                            {t("contato.text-1.1")}

                                <br />
                                <br />

                                {t("contato.text-1.2")}

                                <br />
                                <br />

                                {t("contato.text-1.3")} <a href="https://fitec.gupy.io/">https://fitec.gupy.io/</a>. 
                                {t("contato.text-1.4")}
                                <a href={privacidade} target="_blank">{t("footer.2")}</a> {t("contato.text-1.5")}<a href={privacidade} target="_blank">{t("footer.2")}
                                </a> {t("contato.text-1.6")}

                                <br />
                                <br />

                                {t("contato.text-1.8")}</p>

                            <hr />

                            <a href="https://fitec.gupy.io/" target="_blank" class="link animation wow fadeInLeft" data-wow-delay="0.4s" style={buttonStyle}>{t("home.vagas")}</a>

                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}