import React from "react";
import { useTranslation } from "react-i18next";
import BodyText from "../../../components/body_text/BodyText";
import ImageAndTitle from "../../../components/ImageAndTitle";

function WorkWithUs() {

  const {t} = useTranslation();
    return (
      <div id="trabalhe-conosco">
        <ImageAndTitle imageByClassName={"section-intro-page bg-trabalhe"} content={t("contato.text-1.1")}/>
          <div class = 'section-sub-page bg-cinza-1' style={{"paddingLeft":"80px", "paddingRight":"80px"}}>
            <BodyText 
              content={t("contato.text-1.2")} >
            </BodyText>
            <BodyText 
              content={t("contato.text-1.3")} >
            </BodyText>
            <p>{t("contato.text-1.4")}
              <a href="https://fitec.gupy.io/">Gupy</a> 
              {t("contato.text-1.5")}
               <a href="https://fitec.org.br/downloads/privacidade.pdf">{t("footer.2")}</a>   
               {t("contato.text-1.6")}
               <a href="https://fitec.org.br/downloads/privacidade.pdf">{t("footer.2")}</a> 
               {t("contato.text-1.7")}
            </p>
            <BodyText 
              content={t("contato.text-1.8")} >
            </BodyText>
          </div>
      </div>
    )
}

export default WorkWithUs