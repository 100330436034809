import BodyText from "../../components/body_text/BodyText";
import TitleAndText from "../../components/title_and_text/TitleAndText";

function Fitec() {
  return (
    <div className="Fitec">
      <TitleAndText 
          title='SOBRE A FITEC' 
          content='Criada na década de noventa, a FITec é um Instituto de Ciência e Tecnologia (ICT) credenciado pelos diversos órgão brasileiros, habilitado a   celebrar convênios com empresas beneficiárias das Leis de Incentivo à Pesquisa e Desenvolvimento Tecnológicos. ' >
      </TitleAndText>
      <BodyText 
          content='Seu quadro conta com cerca de 350 colaboradores, sendo composto por doutores, pós-doutores, mestres, pós-graduados, graduados e técnicos, com vasta experiência de mercado e grande qualificação nas áreas de Transformação Digital, Inteligência Artificial, Ciência de Dados, IoT, Gestão, Design Industrial e Engenharia de Produção, distribuídos entre Recife, Campinas, São José dos Campos e Belo Horizonte.  ' >
      </BodyText>
      <BodyText 
          content='Este time, trabalhando isoladamente ou em conjunto com equipes de parceiros de diversas partes do mundo, desenvolveu nos últimos anos projetos de Hardware, Firmware e Software nas áreas de: Telecomunicações, Informática, Energia Elétrica, Equipamentos Biomédicos, Automação Industrial (4.0), Tecnologias Assistivas, Indústria Automotiva e Logística.' >
      </BodyText>
      <BodyText 
          content='A FITec  já desenvolveu mais de 600 projetos de P&D, incluindo a concepção de mais de 200 equipamentos eletrônicos, e suas soluções foram instaladas em mais de 25 países, com casos de sucesso de desenvolvimento de projetos nos setores de Indústria 4.0, Logística, Telecom, Energia, Agroindústria, Mineração, Educação, Saúde. ' >
      </BodyText>
      <BodyText 
          content='A diversidade de assuntos e a complexidade das áreas envolvidas exigem que os colaboradores e a gestão se mantenham sempre atualizados, trabalhando no aperfeiçoamento dos recursos humanos e na formação de novos colaboradores. ' >
      </BodyText>
      <TitleAndText 
          title='GOVERNANÇA' 
          content='Nosso modelo de governança tem como objetivo materializar os princípios que orientam nossas ações: Transparência, Equidade, Prestação de Contas e Responsabilidade Corporativa. As diretrizes e políticas gerais que nos orientam são estabelecidas pelo Conselho Curador, órgão deliberativo da FITec, que monitora a implementação dessas iniciativas pela Diretoria Executiva. Suas decisões são encaminhadas para registro e aprovação do Ministério Público Federal. O Conselho Fiscal assessora o Conselho Curador na análise das nossas finanças. A Diretoria Executiva elabora e executa o programa anual de atividades e gere integralmente a organização. O Conselho Técnico Científico assessora a Diretoria Executiva visando constante evolução tecnológica.' >
      </TitleAndText>
    </div>
  );
}

export default Fitec;