import React from "react";
import "../css/main.css";
import { useMediaQuery } from "react-responsive";
import img_recife from "../../assets/images/img_recife.png";
import img_campinas from "../../assets/images/img_campinas.png";
import img_BH from "../../assets/images/img_belohorizonte.png";
import img_joseCampos from "../../assets/images/img_saojosedoscampos.png";
import img_manaus from '../../assets/images/img_manaus.png'
import { useTranslation } from "react-i18next";

export default function SectionContato() {

    const {t} = useTranslation();
    
    const isMobile = useMediaQuery({ query: "(max-width: 1224px)" });
    return (
    <section class="section-contato">
        <div class="container" id="contato">
            <div class="row">
                <div class="col">
                    <h2 class="wow fadeIn" data-wow-delay="0.4s">{t("menu.contato")}</h2>
                </div>
            </div>
            {!isMobile && 
            <div class="row justify-content-center mb-4">
                <div class="col-auto">
                    <div class="fones">
                        <p>{t("home.fone")}: <b>(19) 2137.6803</b></p>
                    </div>
                </div>

                <div class="col-auto">
                    <div class="fones" id="whatsapp">
                        <p>Whatsapp: <b>(19) 98208.4396</b></p>
                    </div>
                </div>
            </div>}

            {isMobile && 
            <div class="row justify-content-center mb-4">
                <div class="col" style={{width: 165, display: "flex" , justifyContent: "center"}}>
                    <div class="fones">
                        <p>Fone: <b>(19) 2137.6803</b></p>
                    </div>
                </div>

                <div class="col" style={{width: 165, display: "flex" , justifyContent: "center"}} >
                    <div class="fones" id="whatsapp" >
                        <p style={{textAlign:"center"}}>Whatsapp:<br/> <b>(19) 98208.4396</b></p>
                    </div>
                </div>
            </div>}


            

            <div class="row" style={{justifyContent: "center"}}>

                <div class="col-sm-6 col-md-3 mb-3" style={{width: isMobile ? "350px" : "230px", marginLeft:isMobile ? "30px":""}}>
                    <div class="ctn-contato text-center wow fadeInDown" data-wow-delay="0.2s">
                        <img src={img_recife} alt="" class="img-fluid" />
                        <h3 translate="no" style={{"textAlign":"center"}}>Recife/PE</h3><br />
                        <p style={{"textAlign":"center"}}>Rua Madre de Deus, 27 – 16º andar. Bairro Recife - CEP: 50030-906 – Recife, PE</p>
                    </div>
                </div>

                <div class="col-sm-6 col-md-3 mb-3" style={{width: isMobile ? "350px" : "230px", marginLeft:isMobile ? "30px":""}}>
                    <div class="ctn-contato text-center wow fadeInDown" data-wow-delay="0.4s">
                        <img src={img_campinas} alt="" class="img-fluid" />
                        <h3 translate="no" style={{"textAlign":"center"}}>Campinas/SP</h3><br />
                        <p style={{"textAlign":"center"}}>Rua Aguaçu, 79, Bairro Alphaville CEP: 13098-321 - Campinas, SP</p>
                    </div>
                </div>

                <div class="col-sm-6 col-md-3 mb-3" style={{width: isMobile ? "350px" : "225px", marginLeft:isMobile ? "30px":""}}>
                    <div class="ctn-contato text-center wow fadeInDown" data-wow-delay="0.6s">
                        <img src={img_BH} alt="" class="img-fluid" />
                        <h3 translate="no" style={{"textAlign":"center"}}>Belo Horizonte/MG</h3><br />
                        <p style={{"textAlign":"center"}}>Av Cristóvão Colombo, 485 8º Andar CEP: 30140-150 – Belo Horizonte, MG</p>
                    </div>
                </div>

                <div class="col-sm-6 col-md-3 mb-3" style={{width: isMobile ? "350px" : "230px", marginLeft:isMobile ? "30px":""}}>
                    <div class="ctn-contato text-center wow fadeInDown" data-wow-delay="0.8s" style={{"height":"50px"}}>
                        <img src={img_joseCampos} alt="" class="img-fluid" />
                        <h3 translate="no" style={{"textAlign":"center"}}>S. José dos Campos/SP</h3>
                        <p style={{"textAlign":"center"}}>Estrada Altino Bordensan, 500, Sala 2105 Eugênio de Mello - Parque Tecnológico CEP: 12247-016 - São José dos Campos, SP</p>
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 mb-3" style={{width: isMobile ? "350px" : "225px", marginLeft:isMobile ? "30px":""}}>
                    <div class="ctn-contato text-center wow fadeInDown" data-wow-delay="0.8s" style={{"height":"50px"}}>
                        <img src={img_manaus} alt="" class="img-fluid" />
                        <h3 translate="no" style={{"textAlign":"center"}}>Manaus/AM</h3><br />
                        <p style={{"textAlign":"center", marginBottom:"10px"}}>Av Djalma Batista, n°1719 SALA 906, Bairro Chapada, Manaus - AM, CEP 69050-010, Manaus – AM</p>
                    </div>
                </div>
            </div>
        </div>

    </section>
    );
}