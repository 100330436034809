import logo_lei_informatica from "../../../assets/images/fomento/Site-Logo-Lei-da-Informatica.png"
import logo_aneel from "../../../assets/images/fomento/Site-Logo-ANEEL-1.png"
import logo_finep from "../../../assets/images/fomento/Site-Logo-Finep-1.png"
import logo_lei_bem from "../../../assets/images/fomento/Site-Logo-Lei-do-Bem.png"
import logo_softex from "../../../assets/images/fomento/Site-Logo-Softex.png"
import logo_anp from "../../../assets/images/fomento/Site-Logo-ANP-1.png"
import logo_fapesp from "../../../assets/images/fomento/Site-Logo-FAPESP.png"
import logo_mcti from "../../../assets/images/fomento/mcti.jpg"
import logo_bnds from "../../../assets/images/fomento/bnds.jpg"
import logo_facepe from "../../../assets/images/fomento/facepe.jpg"
import logo_rota from "../../../assets/images/fomento/Site-Logo-Rota-2030.png"

function SectionBox() {
    return (
        <>
            <div id="carousel" class="carousel" data-ride="carousel">
                <ol class="carousel-indicators" style={{ bottom: -60}}>
                    <li data-target="#carousel-client" data-slide-to="0" class="active"></li>
                    <li data-target="#carousel-client" data-slide-to="1"></li>
                    <li data-target="#carousel-client" data-slide-to="2"></li>
                    <li data-target="#carousel-client" data-slide-to="3"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row mt-3" >
                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_lei_informatica} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>

                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_aneel} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>

                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_finep} alt="" style={{ width: 250 }} class="wow fadeIn" data-wow-delay="0.4s" /></div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div className="row mt-3">
                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_lei_bem} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>

                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_softex} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>

                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_anp} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div className="row mt-3">
                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_fapesp} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>

                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_mcti} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>

                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_bnds} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="row mt-3">
                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_facepe} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>

                            <div class="col" style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}><img src={logo_rota} alt="" style={{ width: 250 }} class=" wow fadeIn" data-wow-delay="0.4s" /></div>

                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-target="#carousel" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-target="#carousel" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </button>
            </div>
        </>

    );
}

export default SectionBox