function ImageAndTitle({imageByClassName, content}) {
    return (
        <section class={imageByClassName}>
                <div class="container h-100">
                    <div class="row h-100 align-items-center">
                        <div class="col-12 text-center">
                            <h1>{content}</h1>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default ImageAndTitle
