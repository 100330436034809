import ImageAndTitle from "../../components/ImageAndTitle";
import Organograma from "../../assets/images/img_governanca_organograma.jpg";
import FitecPresenca from "../../assets/images/fitec-presenca.png";
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from "react-i18next";

function AboutFitec() {
    const isMobile = useMediaQuery({query : "(max-width: 1224px)"})
    const {t} = useTranslation();
    return (
        <>
            <ImageAndTitle 
            imageByClassName="section-intro-page bg-fitec-sobre"
            content={t("Fitec.1")}/>
            
            <section class="section-sub-page bg-cinza-1" id="sobre">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2>{t("sobre.sobre")}</h2>
                            <div class="row my-4 justify-content-center">
                                {!isMobile 
                                ? <div class="col-8" style={{"marginLeft": "200px"}}>
                                    <div class="embed-responsive embed-responsive-16by9">
                                        <iframe class="embed-responsive-item" width="600" height="337.5"
                                            src="https://www.youtube.com/embed/CoK_BvaTcFw" title="FITEC"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
                                            allowfullscreen">
                                        </iframe>
                                    </div>
                                </div>
                                : <div class="col-8" style={{"marginRight": "105px"}}>
                                <div class="embed-responsive embed-responsive-16by9">
                                    <iframe class="embed-responsive-item" width="800" height="637.5"
                                        src="https://www.youtube.com/embed/CoK_BvaTcFw" title="FITEC"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
                                        allowfullscreen">
                                    </iframe>
                                </div>
                            </div>}

                            </div>
                            <p style={{"padding":"15px",}}>{t("sobre.text-1.1")}
                                <br/><br/>
                                {t("sobre.text-1.2")}
                                <br/><br/>
                               {t("sobre.text-1.3")}
                                <br/><br/>
                                {t("sobre.text-1.4")}
                                <br/><br/>
                                {t("sobre.text-1.5")}
                            </p>
                        </div>
                    </div>

                    {!isMobile 
                    ? <div class="row" style={{"padding":"25px",}}>
                        <div class="col-sm-3 mt-4">
                            <div class="embed-responsive embed-responsive-1by1">
                                <div class="embed-responsive-item ball-fitec">
                                    <div class="row h-100 align-items-center">
                                        <div class="col">
                                            <h6 class="text-capitalize">  {t("sobre.governanca")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 mt-4">
                            <div class="embed-responsive embed-responsive-1by1">
                                <div class="embed-responsive-item ball-fitec">
                                    <div class="row h-100 align-items-center">
                                        <div class="col">
                                            <h6>{t("sobre.social")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 mt-4">
                            <div class="embed-responsive embed-responsive-1by1">
                                <div class="embed-responsive-item ball-fitec">
                                    <div class="row h-100 align-items-center">
                                        <div class="col">
                                            <h6>{t("sobre.sustentabilidade")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3 mt-4">
                            <div class="embed-responsive embed-responsive-1by1" >
                                <div class="embed-responsive-item ball-fitec">
                                    <div class="row h-100 align-items-center">
                                        <div class="col">
                                            <h6>{t("sobre.presenca")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div class="row" style={{"padding":"5px"}}>
                            <div class="col-sm-3 mt-4">
                                <div class="embed-responsive embed-responsive-1by1">
                                    <div class="embed-responsive-item ball-fitec" style={{
                                        height:"114px",
                                        width:"31%",
                                        marginLeft:"40px",
                                        marginBottom:"20px"
                                        }}>
                                        <div class="row h-100 align-items-center">
                                            <div class="col">
                                                <h6 class="text-capitalize" style={{fontSize:"12px"}}>{t("sobre.governanca")}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="embed-responsive-item ball-fitec" style={{height:"114px", width:"31%", marginLeft:"220px"}}>
                                        <div class="row h-100 align-items-center">
                                            <div class="col">
                                                <h6 style={{fontSize:"12px"}}>{t("sobre.social")}</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    {isMobile 
                    ? <div class="row" style={{"padding":"5px"}}>
                        <div class="col-sm-3 mt-4">
                            <div class="embed-responsive embed-responsive-1by1">
                                <div class="embed-responsive-item ball-fitec" style={{
                                    height:"113px",
                                    width:"31%",
                                    marginLeft:"40px",
                                    marginBottom:"20px"
                                    }}>
                                    <div class="row h-100 align-items-center">
                                        <div class="col">
                                            <h6 class="text-capitalize" style={{fontSize:"12px"}}>{t("sobre.sustentabilidade")}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="embed-responsive-item ball-fitec" style={{
                                    height:"113px",
                                    width:"31%",
                                    marginLeft:"220px",
                                    marginBottom:"20px"
                                    }}>
                                    <div class="row h-100 align-items-center">
                                        <div class="col">
                                            <h6 class="text-capitalize" style={{fontSize:"13px"}}>{t("sobre.presenca")}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    : <></>}
                </div>
            </section>
            <ImageAndTitle imageByClassName= "section-intro-page bg-governanca"/>
            <section class="section-sub-page" id="governanca">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 id="governanca">{t("sobre.governanca")}</h2>
                            <p style={{"padding":"15px",}}>{t("sobre.text-2.1")}</p>
                                <div class="row mt-3" style={{"padding":"10px",}}>
                                    <div class="col-12 text-center">
                                    <img src={Organograma} alt="" class="img-fluid"></img>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div class="row mt-5" style={{"padding":"15px",}}>
                        <div class="col-sm-4">
                            <h3>{t("sobre.text-2.2")}</h3>
                            <p>{t("sobre.text-2.3")}</p>
                        </div>
                        <div class="col-sm-4">
                            <h3>{t("sobre.text-2.4")}</h3>
                            <p>{t("sobre.text-2.5")}</p>
                        </div>
                        <div class="col-sm-4">
                            <h3>{t("sobre.text-2.6")}</h3>
                            <p>{t("sobre.text-2.7")}</p>
                        </div>
                    </div>
                </div>
            </section>
            <ImageAndTitle imageByClassName= "section-intro-page bg-responsabilidade"/>
            <section class="section-sub-page bg-cinza-1" id="responsabilidade-social">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 id="responsabilidade-social">{t("sobre.social")}</h2>

                            <p style={{"padding":"15px",}}>
                                {t("sobre.text-3.1")}
                                <br/><br/>
                                {t("sobre.text-3.2")}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <ImageAndTitle imageByClassName="section-intro-page bg-sustentabilidade" />
            <section class="section-sub-page" id="sustentabilidade">
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h2 id="sustentabilidade">{t("sobre.sustentabilidade")}</h2>
                            <p style={{"padding":"15px",}}>
                                 {t("sobre.text-4.1")}
                                <br/><br/>
                                {t("sobre.text-4.2")}
                                <br/><br/>
                                {t("sobre.text-4.3")}
                                <br/><br/>
                            </p>
                        </div>
                    </div>
                    
                    {!isMobile 
                    ? <div class="row mt-4">
                        <div class="col-sm-3">
                            <div class="ctn-rounded bg-cinza-1">
                                <p style={{"textAlign": "center"}}>
                                {t("sobre.text-4.4")}
                                   </p>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="ctn-rounded bg-cinza-1">
                                <p style={{"textAlign": "center"}}>
                                {t("sobre.text-4.5")}
                                   </p>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="ctn-rounded bg-cinza-1">
                                <p style={{"textAlign": "center"}}>
                                {t("sobre.text-4.6")}
                                    </p>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="ctn-rounded bg-cinza-1">
                                <p style={{"textAlign": "center"}}>
                                {t("sobre.text-4.7")}
                                    </p>
                            </div>
                        </div>
                    </div>
                    : <div class="row my-3" style={{"paddingRight": '140px', "justifyContent": "space-between"}}>
                        <div class="col-3">
                            <div class="ctn-rounded bg-cinza-1"  style={{"width": "190px"}}>
                                <p style={{"textAlign": "center", "paddingTop": "5px", "paddingLeft": "10px", "paddingRight": "10px"}}>
                                {t("sobre.text-4.4")}
                                    </p>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="ctn-rounded bg-cinza-1" style={{"justifyContent":"center", "width": "180px"}}>
                                <p style={{"textAlign": "center", "paddingTop": "20px", "paddingLeft": "10px", "paddingRight": "10px"}}>
                                {t("sobre.text-4.5")}
                                   </p>
                            </div>
                        </div>
                    </div>}

                    {isMobile 
                    ? <div class="row my-3" style={{"paddingRight": '140px', "justifyContent": "space-between"}}>
                        <div class="col-3">
                            <div class="ctn-rounded bg-cinza-1" style={{"justifyContent":"center", "width": "190px"}}>
                                <p style={{"textAlign": "center", "paddingTop": "30px", "paddingLeft": "10px", "paddingRight": "10px"}}>
                                {t("sobre.text-4.6")}
                                   </p>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="ctn-rounded bg-cinza-1" style={{"justifyContent":"center", "width": "180px"}}>
                                <p style={{"textAlign": "center", "paddingTop": "20px", "paddingLeft": "10px", "paddingRight": "10px"}}>
                                {t("sobre.text-4.7")}
                                   </p>
                            </div>
                        </div>
                    </div> : <></>}

                </div>
            </section>

            <section class="section-contato section-presenca" id="contato">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <h2 class="wow fadeIn" data-wow-delay="0.4s">{t("sobre.presenca")}</h2>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center">
                        <div class="col-sm-6">
                            <img  src={FitecPresenca} alt="" class="img-fluid wow fadeInDown" data-wow-delay="0.2s"/>
                        </div>
                        <div class="col-sm-4">
                            <h3 class="wow fadeInDown" data-wow-delay="0.2s">Recife - PE</h3>
                            <h3 class="wow fadeInDown" data-wow-delay="0.4s">Campinas - SP</h3>
                            <h3 class="wow fadeInDown" data-wow-delay="0.6s">São Jose dos Campos - SP</h3>
                            <h3 class="wow fadeInDown" data-wow-delay="0.8s">Belo Horizonte - MG</h3>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutFitec