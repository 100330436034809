import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Routes from "./Routes/index.js";

function App() {
  React.useEffect(() => {
    document.title = "FITEC - Inovações Tecnológicas";
  }, []);

  return (
    <>
      <div className="App"></div>
      <Routes />
    </>
  );
}

export default App;
