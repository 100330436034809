import React from "react";
import { useTranslation } from "react-i18next";

function Posts () {

    const {t, i18n} = useTranslation();
    const viewPostButtonByLanguage = i18n.language === 'pt' ? "mt-3 btn-publi link animation" : "mt-3 btn-publi-en link animation"

    return (
    <>
        <div>
            <section className="section-intro-page bg-publicacao" id="inicio-publicacoes">
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                <div className="col-12 text-center">
                    <h1>{t("menu.publicacoes")}</h1>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <p>{t("publicacoes.text-1.1")}
                    </p>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.2")}</h3>
                    <p>{t("publicacoes.text-1.3")}</p>
                    <a href="https://www.fitec.org.br/ProjetoAgro/index.html" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.4")}</h3>
                    <p>{t("publicacoes.text-1.5")}</p>
                    <a href="http://www.inicepg.univap.br/cd/INIC_2019/anais/arquivos/RE_0168_1248_01.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>IoT 5G Design Consulting</h3>
                    <p>{t("publicacoes.text-1.6")}</p>
                    <a href="http://www.fitec.org.br/wp-content/uploads/2021/09/IoT-5G-Design-Consulting.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.7")}</h3>
                    <p>{t("publicacoes.text-1.8")}</p>
                    <a href="http://webserver2.tecgraf.puc-rio.br/~lhf/geoinfo2000/anais/024.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.9")}</h3>
                    <p>{t("publicacoes.text-1.10")}</p>
                    <a href="https://biblioteca.inatel.br/cict/acervo%20publico/Sumarios/Artigos%20de%20TCC/TCC_Pos%20Gradua%C3%A7%C3%A3o/SRST%20Engenharia%20de%20Redes%20e%20Sistemas%20de%20Telecomunica%C3%A7%C3%B5es/2020/TCCRedesParticipantesdoSemin%C3%A1rio/TCC_Desempenho_da_camada_f%C3%ADsica_5G-NR.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1 ">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.11")}</h3>
                    <p>{t("publicacoes.text-1.12")}</p>
                    <a href="http://www.fitec.org.br/wp-content/uploads/2021/09/Samuel-Lima-Monografia.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.13")}</h3>
                    <p>{t("publicacoes.text-1.14")}</p>
                    <a href="http://www.fitec.org.br/wp-content/uploads/2021/09/RAFAEL_ALBUQUERQUE-IPv6-final.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.15")}</h3>
                    <p>{t("publicacoes.text-1.16")}</p><a href="http://www.fitec.org.br/wp-content/uploads/2021/09/ArlingtonRodrigues-RadiusDiameter_final.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.17")}</h3>
                    <p>{t("publicacoes.text-1.18")}</p>
                    <a href="http://revistas.poli.br/index.php/repa/article/view/1358/626" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1 ">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.19")}</h3>
                    <p>{t("publicacoes.text-1.20")}</p>
                    <a href="http://revistas.poli.br/index.php/repa/article/view/1355/620" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.21")}</h3>
                    <p>{t("publicacoes.text-1.22")}</p>
                    <a href="http://revistas.poli.br/index.php/repa/article/view/1324/604" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.23")}</h3>
                    <p>O&amp;G {t("publicacoes.text-1.24")}</p>
                    <a href="https://icongresso.ibp.itarget.com.br/arquivos/trabalhos_completos/ibp/3/final.IBP0307_20_18072020_122331.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.25")}</h3>
                    <p>{t("publicacoes.text-1.26")}</p>
                    <a href="https://www.lcv.fee.unicamp.br/images/BTSym-19/Papers/095.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.27")}</h3>
                    <p>{t("publicacoes.text-1.28")}</p>
                    <a href="https://link.springer.com/chapter/10.1007/978-3-030-16053-1_41" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.29")}</h3>
                    <p>{t("publicacoes.text-1.30")}</p>
                    <a href="https://xxvisnptee.com.br/edicoes-anteriores/" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1 ">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.31")}</h3>
                    <p>{t("publicacoes.text-1.32")}</p>
                    <a href="http://www.bvr.com.br/snptee/xxvsnptee/trabalhos/GDS/3633.php" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page ">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.33")}</h3>
                    <p>{t("publicacoes.text-1.34")}</p>
                    <a href="https://www.scielo.br/scielo.php?pid=S1807-17752017000300385&script=sci_abstract&tlng=en" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.35")}</h3>
                    <p>{t("publicacoes.text-1.36")}</p>
                    <a href="LINK" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.37")}</h3>
                    <p>{t("publicacoes.text-1.38")}</p>
                    <a href="https://eventos.abcm.org.br/cobem2019/" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page bg-cinza-1 ">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.39")}</h3>
                    <p>{t("publicacoes.text-1.40")}</p>
                    <a href="http://www.fitec.org.br/wp-content/uploads/2021/09/SBSE_2020_121533-978069.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
            <section className="section-sub-page">
            <div className="container">
                <div className="row">
                <div className="col-12">
                    <h3>{t("publicacoes.text-1.41")}</h3>
                    <p>{t("publicacoes.text-1.42")} </p>
                    <a href="http://www.fitec.org.br/wp-content/uploads/2021/09/SBSE_2020_BESS.pdf" className={viewPostButtonByLanguage} target="_blank" rel="noreferrer">{t("publicacoes.link")}</a>
                </div>
                </div>
            </div>
            </section>
        </div>
    </>
    );
}

export default Posts