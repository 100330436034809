import React from "react";
import "../css/main.css";
import "./style.css";
import logoFooter from "../../assets/images/logo-footer.svg";
import logoIso from "../../assets/images/iso-iec-27001-footer.svg"
import menuFooter from "../../assets/images/menu-footer.svg";
import { useMediaQuery } from 'react-responsive'
import linkedin from "../../assets/images/social/linkedin.svg";
import youtube from "../../assets/images/social/youtube.svg";
import privacidade from "../../assets/pdf/privacidade.pdf"
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";


export default function Footer() {
    const {t} = useTranslation();
    const isMobile = useMediaQuery({query: "(max-width: 1224px)"})
  return (

        <footer>
            <div class="container">
                {!isMobile 
                ? <div class="row justify-content-center">
                    <div class="col-auto text-center">
                        <nav class="navbar navbar-expand-lg menu-footer">
                            <button class="navbar-toggler button-menu-footer" type="button" data-toggle="collapse"
                                data-target="#menufooter" aria-controls="menufooter"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"><img src={menuFooter} alt="" class="img-fluid"/></span>
                            </button>
                            <div class="collapse navbar-collapse" id="menufooter">
                                <ul class="navbar-nav mr-auto">

                                    <li class="nav-item"><HashLink class="nav-link" to="/sobre#sobre">{t('menu.sobre')}</HashLink></li>
                                    <li class="nav-item"><HashLink class="nav-link" to="/inovacao#inicio-inovacao">{t('atuacao.pd')}</HashLink></li>
                                    <li class="nav-item"><HashLink class="nav-link" to="/engenharia#inicio-engenharia">{t('atuacao.engenharia')}</HashLink></li>
                                    <li class="nav-item"><HashLink class="nav-link" to="/solucoes#sustentabilidade">{t('menu.solucao')}</HashLink></li>
                                    <li class="nav-item"><HashLink class="nav-link" to="/posts#inicio-publicacoes">{t('menu.publicacoes')}</HashLink></li>
                                    <li class="nav-item"><HashLink class="nav-link" to="/oportunidade#trabalhe-conosco">{t('menu.oportunidades')}</HashLink></li>
                                    <li class="nav-item"><HashLink class="nav-link" to="/#contato">{t('menu.contato')}</HashLink></li>

                                </ul>
                            </div>
                        </nav>
                    </div>
                </div> 
                :<> 
                    <div  class="row justify-content-center" style={{padding:"9px", paddingTop:"0", marginBottom:"10px"}}>
                        <div class="col-auto">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <HashLink 
                                        class="nav-link" 
                                        to="/sobre#sobre"
                                        style={{color:"white", fontSize:"15px"}}>{t('menu.sobre')}</HashLink></li>
                            </ul>
                        </div>
                        <div class="col-auto">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <HashLink 
                                        class="nav-link" 
                                        to="/inovacao#inicio-inovacao"
                                        style={{color:"white", fontSize:"15px"}}>{t('atuacao.pd')}</HashLink></li>
                            </ul>
                        </div>
                        <div class="col-auto">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <HashLink 
                                        class="nav-link" 
                                        to="/engenharia#inicio-engenharia"
                                        style={{color:"white", fontSize:"15px"}}>{t('atuacao.engenharia')}</HashLink></li>
                            </ul>
                        </div>
                        <div class="col-auto">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <HashLink 
                                        class="nav-link" 
                                        to="/solucoes#inicio-solucao"
                                        style={{color:"white", fontSize:"15px"}}>{t('menu.solucao')}<br/><br/></HashLink></li>
                            </ul>
                        </div>
                        <div class="col-auto">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <HashLink 
                                        class="nav-link" 
                                        to="/posts#inicio-publicacoes"
                                        style={{color:"white", fontSize:"15px"}}>{t('menu.publicacoes')}</HashLink></li>
                            </ul>
                        </div>
                        <div class="col-auto">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <HashLink 
                                        class="nav-link" 
                                        to="/oportunidade#trabalhe-conosco"
                                        style={{color:"white", fontSize:"15px"}}>{t('menu.oportunidades')}</HashLink></li>
                            </ul>
                        </div>
                        <div class="col-auto">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item">
                                    <HashLink 
                                        class="nav-link" 
                                        to="/#contato"
                                        style={{color:"white", fontSize:"15px"}}>{t('menu.contato')}</HashLink></li>
                            </ul>
                        </div>
                    </div>
                </>}
                
                <div class="row py-2">
                    <div class="col-12 text-center social-icons">
                        <a href="https://www.linkedin.com/company/fitec/mycompany/"><img src={linkedin} alt="" class="img-fluid"/></a>
                        <a href="https://www.youtube.com/channel/UCBI2_0oM8C8TaAIycOfCWnw/"><img src={youtube} alt="" class="img-fluid"/></a>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center', gap:'8px'}}>
                        <img src={logoFooter} alt="" class="img-fluid logo-footer"/>
                        <img src={logoIso} alt="" class="img-fluid logo-iso"/>
                    </div>
                    <div class="col-12 text-center">
                        <p class="copy mt-5" style={{"textAlign": "center"}}>	
                            &copy; {t("footer.1")}
                            <br />
                            <a href={privacidade} target="_blank" rel="noreferrer" style={{color:"white"}}>{t("footer.2")}</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>

    );
}